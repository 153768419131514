<template>
  <div class="question column is-half">
    <div class="question-content card">
      <!-- <div class="card-content" v-html="decodeHtml(question.question[ lang ])"></div> -->
    </div>
  </div>
</template>

<script>
import { QuestionMixin } from "@/mixins/question";

export default {
  name: "TextDisplay",
  mixins: [QuestionMixin],
};
</script>
